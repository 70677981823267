
.wrapper {
  display: flex;
  width: 100%;
}


@media (min-width: 1440px) {

  .wrapper {
    //justify-content: space-between;
  }


}