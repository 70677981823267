@import './reset.scss';

@font-face {
  font-family: 'Roboto';
  src: url(../assets/fonts/Roboto-Regular.ttf);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url(../assets/fonts/Roboto-Medium.ttf);
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url(../assets/fonts/Roboto-Bold.ttf);
  font-style: normal;
  font-weight: 700;
}
body {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-size: 10px;
  line-height: 140%;
}

.swiper-pagination-bullet {
  background: #ffffff;
  opacity: 0.5;
}

.swiper-pagination-bullet-active {
  opacity: 1;
}

.swiper-button-next {
  background-image: url(./../assets/icons/arrow_next.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  width: 7px;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 29px;
  left: auto;
}

.swiper-button-prev {
  background-image: url(./../assets/icons/arrow_prev.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  width: 7px;
}

.swiper-button-prev::after {
  display: none;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 20px;
  right: auto;
}

@media (max-width: 800px) {

  .my_swiper {
    .swiper-pagination-bullet {
      background: #2fa638;
      opacity: 0.5;
    }

    .swiper-pagination-bullet-active {
      opacity: 1;
    }
    .swiper-pagination,
    .swiper-pagination-clickable,
    .swiper-pagination-bullets,
    .swiper-pagination-horizontal {
      bottom: 35px;
    }

    .swiper-slide {
      max-width: 1200px;
      height: auto;
      text-align: center;
      line-height: 0;
      width: auto !important;
      margin: 0 auto;
    }

    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      bottom: 35px;
      left: 0px;
    }
  }

  .swiper-pagination-bullet {
    background: #ffffff;
    opacity: 0.5;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 12px;
    left: 126px;
    width: 100%;
  }

  .swiper-button-prev {
    display: none;
  }
  .swiper-button-next {
    display: none;
  }
}
