.sidebar_wrapper {
}

.wrapper {
  background: white;
  width: 5.47vw;
  transition: width 0.3s;
  height: 100%;
  position: fixed;
  z-index: 1;
  overflow-x: hidden;
  overflow-y: auto;
  //direction: rtl;

  .menu_title {
    display: none;
  }


}

.wrapper::-webkit-scrollbar {
  width: 4px;
  //height: 10px;
}

.wrapper::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom right, #4d7fff 0%, #04B0FB 100%);
  border-radius: 5px;
}

.container {
  //margin-left: 23px;
  margin-right: 1.30vw;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: auto;
  position: fixed!important;
}

.logo {
  margin-top: 1.25vw;
  margin-left: 0.16vw;
  width: 2.45vw;
  height: 41px;
  margin-bottom: 0.78vw;
}

//.division_line {
//  width: 200px;
//  height: 10px;
//  border-bottom: 1px solid #e6e7e9;
//  opacity: 0.2;
//}

.user {
  display: flex;
  align-items: center;
  background: #9eb008;
  border-radius: 8px;
  margin-top: 1.25vw;
  margin-bottom: 1.77vw;
  padding-right: 1.46vw;
}

.avatar {
  margin: 0.99vw 0.63vw 0.99vw 0.83vw;
}

.user_info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  width: 64%;
  overflow-wrap: break-word;
}

.user_name {
  font-weight: 500;
  font-size: 0.73vw;
  line-height: 140%;
  letter-spacing: -0.3px;
  color: #ffffff;
  margin-right: 2.08vw;
  max-width: 110px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: start;

}

.logOut {
  background: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.3px;
  color: #999999;
}

.user_role {
  font-weight: 500;
  font-size: 0.63vw;
  line-height: 140%;
  letter-spacing: -0.3px;
  color: rgba(255, 255, 255, 0.42);
}

.arrow_up {
  margin-top: 2px;
  margin-left: 10.63vw;
  fill: #04B0FB;
}

.arrow_up2 {
  margin-top: 2px;
  margin-left: 11.88vw;
  fill: #04B0FB;
}

.arrow_up3 {
  margin-top: 2px;
  margin-left: 9.58vw;
  fill: #04B0FB;
}

.menu {
  margin-top: 5%;

}

.menu_item {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 1.35vw;
  font-weight: 500;
  font-size: 0.83vw;
  line-height: 140%;
  letter-spacing: -0.3px;
  color: #999999;
  cursor: pointer;
  height: 3.13vw;
  width: 4.69vw;

  &:hover {
    color: #ffffff;
    background: #04B0FB;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
  }
}

.menu_item {
  //fill: red;
  &:hover {
    svg {
      fill: white;
    }

  }
}

svg.menu_first_svg {
  fill: #04B0FB;
}

.menu_icon {
  width: 1.15vw;
  margin: 0 0 0 2.08vw;
  fill: #999999;

  svg {
    fill: #999999;
  }

  &:hover {
    //fill: #dee6a2;
  }
}

.collapse {
  display: flex;
  align-items: center;
  gap: 9px;
  cursor: pointer;

}

.collapse_title {
  font-weight: 500;
  font-size: 0.63vw;
  line-height: 140%;
  letter-spacing: -0.3px;
  color: #999999;
}

.personal_list {
  margin-left: 47px;
  display: flex;
  flex-direction: column;
  border-left: 2px solid #04B0FB;
  border-bottom: 2px solid #04B0FB;
  width: 16.67vw;
  margin-top: -1.35vw;
  padding-top: 0.78vw;
}

.personal_item {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 1.04vw;
  font-weight: 500;
  font-size: 0.73vw;
  line-height: 140%;
  letter-spacing: -0.3px;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;

  &:hover {
    color: #ffffff;
    background: #04B0FB;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;

    svg {
      fill: white;
    }

  }

}


//////////mini side////////////////////////////////////////////////////
.hide {
  //display: none;
  color: #ffffff;
  background: #04B0FB;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;

  svg {
    fill: white;
  }
}

.big_arrow_up {
  margin-left: 50%;
}

.big_arrow_up2 {
  margin-left: 47%;
}

.big_arrow_up3 {
  margin-left: 54%;
}

.big_arrow_up4 {
  margin-left: 44%;
}

@media (max-width: 1801px) {
  .big_arrow_up {
    margin-left: 49%;
  }

  .big_arrow_up2 {
    margin-left: 46%;
  }

  .big_arrow_up3 {
    margin-left: 53%;
  }

  .big_arrow_up4 {
    margin-left: 42%;
  }
}

@media (max-width: 1601px) {
  .big_arrow_up {
    margin-left: 48%;
    padding: 1px;
  }

  .big_arrow_up2 {
    margin-left: 45%;
    padding: 1px;
  }

  .big_arrow_up3 {
    margin-left: 52%;
    padding: 1px;
  }

  .big_arrow_up4 {
    margin-left: 41%;
    padding: 1px;
  }
}

@media (max-width: 1441px) {
  .big_arrow_up {
    margin-left: 47%;
    padding: 4px;
  }

  .big_arrow_up2 {
    margin-left: 44%;
    padding: 4px;
  }

  .big_arrow_up3 {
    margin-left: 51%;
    padding: 4px;
  }

  .big_arrow_up4 {
    margin-left: 40%;
    padding: 4px;
  }
}

@media (max-width: 1310px) {
  .big_arrow_up {
    margin-left: 42%;
  }

  .big_arrow_up2 {
    margin-left: 39%;
  }

  .big_arrow_up3 {
    margin-left: 46%;
  }

  .big_arrow_up4 {
    margin-left: 36%;
  }
}

.mini_wrapper {
  width: 22.08vw;
  transition: width 0.3s;

  .menu_title {
    display: block;
  }

  &:before {
    content: '';
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(3, 3, 3, 0.2);
    background-size: cover;
    filter: blur(2px);
  }
}

.mini_user {
  background: none;
  margin: 0;
  padding-right: 0;
}

.mini_item {
  display: flex;
  text-align: center;
  width: 21.04vw;
  margin-right: 1.09vw;


}

.menu_item_title {

}

.mini_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  position: absolute;
  //left: 50%;
  //transform: translateX(-50%);
  z-index: 10;
  background: #fff;

}

.mini_logo {
  margin: 0;
  margin-top: 1.2vw;
  margin-bottom: 1.04vw;
  margin-right: 16.9vw;
}

.mini_avatar {
  margin: 0;
  margin-bottom: 3.91vw;
}

.mini_personalList {
  //margin: 0;
  //margin-right: 270px;
}

.mini_personal {
  align-items: center;
  text-align: center;
  color: #999999;
  margin-bottom: 0.78vw;
  height: 3.13vw;
  position: relative;

  &::before {
    content: "";
    display: block;
    width: 1.15vw;
    height: 2px;
    background: #04B0FB;
    position: absolute;
    left: 3%;
  }
}


////////////////////////////////////////////////////////////////////////
.active1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.personal_list_btn {
  color: #ffffff;
  background: #04B0FB;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;

  svg {
    fill: white;
  }
}


@media (max-width: 800px) {

  .logo,
  .division_line,
  .user {
    display: none;
  }

  .wrapper {
    padding-top: 2.81vw;
    position: fixed;
    height: 100vh;
    top: 0;
    width: 100%;
    transform: translateX(-100%);
    transition: all 0.5s;
    background: linear-gradient(90deg, #c5d25d 49.48%, rgba(197, 210, 93, 0.75) 100%);
    z-index: 11;
    overflow-y: scroll;
  }

  .active {
    transform: translateX(0);
  }

  //.collapse {
  //  display: none;
  //}
}
