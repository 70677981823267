.wrapper {
  display: flex;
}

.container {
  width: 1146px;
  margin-left: 122px;
  margin-top: 90px;
}

.text {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.3px;
  width: 606px;
  text-align: center;
  margin-bottom: 71px;
}

.menu {
  display: flex;
  flex-direction: column;
  margin-left: 245px;
  gap: 17px;
  margin-bottom: 476px;
}

.menu_item {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.3px;
}

.service_item {
  width: 168px;
  height: 157px;
  border: 1px solid #e6e7e9;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.service_title,
.service_price {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.3px;
  text-align: center;
}

.service_container {
  display: flex;
  gap: 60px;
  flex-wrap: wrap;
}
