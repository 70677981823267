.container {
  //max-width: 1920px;
  max-width: 100%;
  background: rgb(246, 250, 254);
  //margin: 0 0 0 5.21vw;
  padding: 0 50px;
}

.today_block {
  display: flex;
  flex-direction: column;
  margin-bottom: 19px;
  align-items: flex-start;
  padding: 0 50px;
}

.cleaners_data {
  > div {
    display: flex;
    gap: 1.56vw;
    margin-bottom: 65px;

    > div:nth-child(1) button {
      width: 13.13vw;
      height: 4.43vw;
      border-radius: 4px;
      background-color: #04B0FB;
      color: white;
      font-size: 1.04vw;
      font-weight: 700;
      box-shadow: 0 4px 12px 0 #00000029;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }
    }

    > div:nth-child(2) {
      position: relative;
      display: flex;
      align-items: center;
      font-size: 1.25vw;
      font-weight: 600;
      color: #04B0FB;
      justify-content: space-between;
      padding: 1vw;
      background-color: white !important;

      span:nth-child(1) span:nth-child(2) {
        color: #04B0FB;
        position: absolute;
        margin-left: 7px;
      }

      span:nth-child(2) {
        color: black;
        margin-top: 7px;
      }
    }

    > div:nth-child(3),
    > div:nth-child(4) {
      padding: 0.83vw;
      position: relative;

      div {
        display: flex;
        justify-content: space-between;

        > span:nth-child(1) {
          color: #04B0FB;
          font-size: 0.83vw;

          span {
            position: absolute;
            top: 29%;
          }
        }

        > span:nth-child(2) {
          font-size: 1.25vw;
          font-weight: 500;
          position: relative;
          top: 6px;
        }
      }

      div:nth-child(2) {

        span {
          position: absolute;
          top: 67%;

          a {
            color: #999999;
          }
        }
      }

    }


    > div:nth-child(2),
    > div:nth-child(3),
    > div:nth-child(4) {
      width: 15.63vw;
      height: 4.43vw;
      border-radius: 4px;
      background-color: #DEF3FD;
      box-shadow: 0 4px 12px 0 #00000029;
    }
  }
}

.cleaners_data > div > div:nth-child(3) > div:nth-child(1)  span:nth-child(1) > span {
  margin-left: 0.2vw;
}
.cleaners_data > div > div:nth-child(4) > div:nth-child(1)  span:nth-child(1) > span{
  margin-left: 0.4vw;
}
.cleaners_list {
  padding: 40px;
  background-color: white;
  border-radius: 4px;
  margin-bottom: 100px;
  box-shadow: 0 4px 12px 0 #00000014;
  //width: 87.08vw;
}

.cleaners_position_input {
  display: flex;
  justify-content: space-between;

  div:nth-child(2) {
    display: flex;
    gap: 25px;

    div:nth-child(1) {
      button {
        font-size: 0.83vw;
        color: #04B0FB;
        border: 1px solid #04B0FB;
        background-color: white;
        border-radius: 4px;
        width: 10.42vw;
        //height: 2.08vw;
        height: 40px;
      }
    }

    div:nth-child(2) {
      button {
        background-color: white;
        height: 2.08vw;
        width: 2.08vw;;
      }
    }
  }
}

.cleaners_input {
  height: 40px !important;
  width: 25.83vw !important;
}

.cleaners_submit {
  left: 24vw !important;
  top: 24% !important;
}

.cleaners_title div {
  display: flex;
  //width: 100%;
  height: 3.54vw;
  background: #04B0FB;
  color: white;
  font-size: 1vw;
  align-items: center;
  justify-content: center;
  border-radius: 4px 4px 0 0;

  svg {
    //fill: white;
    background: #04B0FB;
  }

}

.cleaners_columns_position,
.cleaner_item {

  > div:nth-child(1) {
    width: 4%;
  }

  div:nth-child(2) {
    //width: 15.05vw;
    width: 18.05vw;
    position: relative;
  }

  div:nth-child(3) {
    width: 9.58vw;
  }

  div:nth-child(4) {
    width: 6.25vw;
    position: relative;
  }

  div:nth-child(5) {
    //width: 11%;

    width: 8%;
    position: relative;
    display: flex;
    align-items: center;

    > span {
      position: relative;
      //position: absolute;
      //margin-left: 5px;
    }
  }

  div:nth-child(6) {
    //width: 5vw;
    width: 8vw;
    position: relative;
  }

  div:nth-child(7) {
    width: 9.58vw;
  }

  div:nth-child(8) {
    width: 6.25vw;
    position: relative;

    svg {
      position: absolute;
      top: 1.8vw;
      left: 4.7vw;
    }
  }

  div:nth-child(9) {
    //width: 18%;
    width: 13%;
    display: flex;
    justify-content: center;

    span button {
      background: white;
    }

  }

  div:nth-child(10) {

  }
}

.cleaner_item {
  display: flex;
  font-size: 0.83vw;
  height: 3.54vw;
  justify-content: center;
  align-items: center;
}

.cleaners_block {
  margin-top: 40px;

  > li:nth-child(odd) {
    background: #F6FAFE;
  }
}

.cleaners_list_btn {
  position: relative !important;
  top: 5vw !important;
  left: 2.4vw !important;
}

.cleaner_container_star {
  //padding: 16px;
  margin: 16px auto;
}

.cleaner_stars_position {
  display: flex;
  justify-content: center;
  gap: 5px;

  span {
    height: 16px;
    display: inline-flex;
  }
}

.star_item {
  position: relative;
  cursor: pointer;
}

.stars_radio_input {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
}

.hide_card_data_span {
  width: 9.4vw;
  display: inline-block;

}

.cleaners_settings_table_block {
  position: absolute;
  width: 27.92vw;
  height: 41.67vw;
  padding: 30px;
  background: white;
  right: 4%;
  z-index: 40;

  span {
    display: flex;
    font-size: 0.83vw;
  }
}

.hiding_block {
  &:before {
    content: '';
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(3, 3, 3, 0.2);
    background-size: cover;
    filter: blur(2px);
  }
}

#cleaners_filter_name {
  background: white;
  border-radius: 4px;
  width: 9.43vw;
  height: 10.42vw;
  padding: 1.04vw 0.83vw;
  z-index: 10;
  position: absolute;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 12px 0 #00000014;
  color: #999999;
  gap: 0.7vw;
  top: 3vw;

  div {
    background: white;
    width: 100%;
    color: #999999;
    font-size: 0.83vw;
    justify-content: left;
  }

  div:nth-child(3) button:nth-child(1) {
    color: white;
    background-color: #04B0FB;
    border-radius: 4px;
  }

  div:nth-child(4) button:nth-child(1) {
    background-color: white;
    color: #04B0FB;
    border-radius: 4px;
    border: 1px solid #04B0FB;
  }

  button {
    width: 7.76vw;
    height: 1.82vw;

  }
}

.cleaners_filter_rating {
  width: 6.15vw !important;
  height: 4.38vw !important;
  background-color: white !important;
  border-radius: 4px !important;
  color: #999999 !important;
  font-size: 0.83vw !important;
  box-shadow: 0 4px 12px 0 #00000014;
  position: absolute !important;
  top: 3vw;
  z-index: 10;
  line-height: 1.7vw;

  svg {
    background-color: white !important;
  }

  ul li:nth-child(1) span:nth-child(1) > span {
    position: relative;
    top: 0.2vw;
  }

  ul li:nth-child(2) span:nth-child(1) > span {
    position: relative;
    top: 0.3vw;
    right: 0.05vw;
  }

}

.cleaners_cities_list {
  height: 22.92vw !important;
  width: 10.57vw !important;
  background-color: white !important;
  box-shadow: 0 4px 12px 0 #00000014 !important;
  border-radius: 4px !important;
  display: flex !important;
  flex-direction: column !important;
  position: absolute !important;
  z-index: 1;
  top: 3vw;

  div {
    background-color: white !important;
  }
}

#cleaners_cities_list_position {
  color: #999999;
  display: flex !important;
  flex-direction: column !important;
  width: 10.57vw !important;
  height: 22.92vw;
  padding: 10px;
  font-size: 0.83vw !important;

  div {
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
  }

  div:nth-child(1) {
    color: #999999;
    display: flex !important;
    flex-direction: column !important;
    width: 10.57vw !important;
    height: 22.92vw;
    padding: 10px;
    font-size: 0.83vw !important;

    ul li {
      line-height: 1.6vw;
      text-align: left;
      font-size: 0.83vw;

      span {
        //width: 100%;
      }
    }
  }

  div:nth-child(2) {
    display: flex !important;
    flex-direction: column !important;
    justify-content: end;

    button {
      color: white;
      background-color: #04B0FB;
      border-radius: 4px;
      width: 8.91vw;
      height: 1.82vw !important;
    }
  }


  div:nth-child(3) {
    display: flex !important;
    flex-direction: column !important;
    //justify-content: end;

    button {
      background-color: white;
      color: #04B0FB;
      border-radius: 4px;
      border: 1px solid #04B0FB;
      width: 8.91vw;
      height: 1.82vw !important;
    }
  }
}

.cleaner_delete_block {
  position: fixed;
  //position: absolute;
  z-index: 99999;
  width: 30.78vw;
  height: 9.17vw;
  background-color: white !important;
  border-radius: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex !important;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 1vw;

  div:nth-child(1) span {
    font-size: 1.25vw;
  }
}

.cleaner_delete {
  display: flex;
  justify-content: center;
  gap: 1.3vw;

  button {
    width: 5.21vw;
    height: 1.82vw;
    border-radius: 4px;
    color: white;
    font-size: 0.83vw;
  }

  div:nth-child(1) button {
    background-color: #F93F3F;
  }

  div:nth-child(2) button {
    background-color: #04B0FB;
  }
}

.cleaner_removal_reason {
  position: fixed;
  //position: absolute;
  z-index: 99999;
  width: 34.84vw;
  height: 25.99vw;
  background-color: white !important;
  border-radius: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  //display: flex !important;
  //flex-direction: column;
  //justify-content: space-evenly;
  padding: 2.08vw;
  font-size: 0.83vw;
  text-align: left;
  line-height: 2vw;

  ul li {
    line-height: 0.99vw;
  }

  li:nth-child(1) {
    font-size: 1.25vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    bottom: 0.4vw;

    div:nth-child(2) {
      position: relative;
      top: 0.2vw;
    }
  }

  textarea {
    border-radius: 4px;
    border: 1px solid #04B0FB;
    width: 30.68vw !important;
    height: 5.21vw !important;
    margin-top: 0.4vw;
    resize: none;
    padding: 10px;

    //::placeholder {
    //  padding: 10px;
    //}
  }

  button {
    background-color: #F93F3F;
    width: 30.68vw;
    height: 2.24vw;
    color: white;
    border-radius: 4px;
    margin-top: 0.6vw;
  }
}


input[type=checkbox] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  outline: none;
  content: none;
}

input[type=checkbox]:before {
  //font-family: "FontAwesome";
  content: "\f00c";
  font-size: 15px;
  color: transparent !important;
  background: white;
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid black;
  border-radius: 5px;
  //margin-right: 7px;
}

input[type=checkbox]:checked:before {
  padding: 0 0 0 2px;
  //content: "\2713";
  font-size: 21px;
  background-color: #04B0FB;
  //background: url("src/assets/tickOK.svg");
  //color: white !important;
  border: none;
}
