.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.MuiDataGrid-columnSeparator {
    visibility: hidden;
}

.MuiDataGrid-root {
    border: none !important;
    padding: 0 40px;
    height: 336px !important;
    overflow-y: auto;
    overflow-x: hidden;
}

.MuiDataGrid-cell {
    border-bottom: none !important;
}

.MuiDataGrid-columnHeaders {
    color: white;
    background: #04B0FB;
    border: 1px solid #e6e7e9;
    /*border-radius: 8px !important;*/
}

.MuiDataGrid-row {
    border: 0.5px solid #e6e7e9;
    /*border-radius: 8px !important;*/
    /*margin-top: 9px;*/
    width: calc(100% - 2px) !important;

}

.MuiDataGrid-row > div:nth-child(4) {

}

.MuiDataGrid-row > div:nth-child(5) {
    /*position: relative;*/
    /*left: 11%;*/
}

.MuiDataGrid-virtualScrollerRenderZone {
    padding-right: 5px !important;
}

.MuiDataGrid-virtualScrollerRenderZone div {
    outline: none !important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight) > .MuiDataGrid-cell {
    overflow: visible !important;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.css-yrdy0g-MuiDataGrid-columnHeaderRow > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) {
    /*display: flex;*/
    /*justify-content: center;*/
}

.css-yrdy0g-MuiDataGrid-columnHeaderRow > div:nth-child(4) > div > div:nth-child(1),
.css-yrdy0g-MuiDataGrid-columnHeaderRow > div:nth-child(5) > div > div:nth-child(1) {
    margin-left: 20%;
}

.MuiDataGrid-columnHeaderTitleContainer{
    /*margin-left: 20%;*/
}

.css-yrdy0g-MuiDataGrid-columnHeaderRow > div:nth-child(5) > div > div:nth-child(1) {
    margin-left: 40%;
}
.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .css-1e2bxag-MuiDataGrid-root .MuiDataGrid-cell:focus{
    outline: none!important;
}
.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .css-1e2bxag-MuiDataGrid-root .MuiDataGrid-cell:focus-within{
    outline: none!important;
}
@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media (max-width: 800px) {
    .MuiDataGrid-columnHeaders {
        border: none !important;
    }

    .MuiDataGrid-columnHeadersInner > div > .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnHeaderTitleContainer {
        display: block;
    }

    .MuiDataGrid-columnHeadersInner > div > .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnHeaderTitle {
        margin-left: auto;
    }

    .MuiDataGrid-columnHeaderTitle {
        font-size: 12px;
    }

    .MuiDataGrid-root {
        padding-left: 5px;
        padding-right: 5px;
    }
}
