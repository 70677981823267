.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background: linear-gradient(111.34deg, #b9c940 0%, #dfe7a6 100%);
}

.container {
  max-width: 418px;
  margin: 0 auto;
  margin-bottom: 129px;
  background: #ffffff;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.05), 0px 6px 30px rgba(0, 0, 0, 0.04), 0px 8px 10px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;

}

.logo {
  margin-top: 116px;
  margin-bottom: 34px;
}

.title {
  margin-top: 40px;
  margin-bottom: 12px;
  color: rgba(24, 24, 25, 0.9);
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.5px;
}

.subTitle {
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.6px;
  color: rgba(24, 24, 25, 0.7);
}

.link {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.6px;
  color: #3855b3;
}

.singIn_btn {
  margin: 0px 40px 24px 40px;
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: inherit;
  border: 1px solid #e6e7e9;
  border-radius: 4px;
  cursor: pointer;
}

.google_logo {
  padding-left: 87px;
}

.title_btn {
  padding: 15px 84px 15px 0px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.4px;
  color: rgba(24, 24, 25, 0.9);
}

.line {
  margin: 0px 40px;
  color: rgba(24, 24, 25, 0.42);
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.3px;
}

.line::after,
.line::before {
  margin: 0 16px;
  color: #e6e7e9;
  content: '\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0';
  text-decoration: line-through;
}

.login {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.email {
  margin-top: 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.login_text {
  position: absolute;
  left: 0;
  top: -15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.5px;
  color: rgba(24, 24, 25, 0.9);
}

.login_input {
  margin-top: 8px;
  margin-bottom: 24px;
  padding: 13px 20px;
  border: 1px solid #e6e7e9;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.6px;
  width: 337px;
}

.password_wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.password_text {
  margin-left: 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.5px;
  color: rgba(24, 24, 25, 0.9);
}

.password_input {
  margin-top: 8px;
  margin-bottom: 30px;
  padding: 13px 20px;
  border: 1px solid #e6e7e9;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.6px;
  width: 337px;
}

.password_items {
  display: flex;
  align-items: center;
  margin-bottom: 34px;
  gap: 10px;
}

.password_stay {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.5px;
  color: rgba(24, 24, 25, 0.9);
}

.login_btn {
  background: #bbca43;
  border-radius: 4px;
  margin-bottom: 40px;
  border: none;
  padding: 13px 146px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.4px;
  color: #ffffff;
  cursor: pointer;
}