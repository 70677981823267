.container {
  max-width: 396px;
  width: 100%;

  .title-count,
  .title {
    font-size: 20px;
    line-height: 24px;
    font-family: Inter, sans-serif;
    font-weight: 600;
    color: var(--main-black);
  }

  .title {
    margin-bottom: 28px;
    text-align: start;
  }

  .title-count {
    border-bottom: 1px solid var(--light-blue);
    text-align: center;
    padding: 0 4px;
  }

  .times-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;

    .time-card {
      border-radius: 4px;
      background-color: var(--light-blue-gray);

      width: 100%;
      height: 43px;

      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      border: 1px solid transparent;

      &:hover {
        border: 1px solid var(--light-blue);
        background-color: var(--white);

        .time-card-text {
          color: var(--main-black);
        }
      }

      .time-card-text {
        color: var(--main-gray);
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
        font-family: Inter, sans-serif;
      }

      &.selected {
        background-color: var(--light-blue);

        .time-card-text {
          color: var(--white);
        }
      }
    }
  }
}
