.wrapper {
  display: flex;
}

.container {
  //max-width: 1920px;
  max-width: 100%;
  background: rgb(246, 250, 254);
  margin: 0 0 0 5.21vw;
}
.staff_container{
  max-width: 1920px;
  //width: 100%;
  background: rgb(246, 250, 254);
  margin: 0 0 0 5.21vw;
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  background: white;
  padding: 0 50px;
  height: 5vw;
}

.header_input {
  display: flex;
  flex-grow: 1;
  position: relative;
  align-items: center;

  input {
    position: relative;
    top: 19%;
    width: 30vw;
    height: 2.92vw;
    padding: 0 0 0 10px;
    color: #999999;


    &:focus + input[type="submit"] {
      background: url(../loupeBlue.svg) no-repeat 50% 50%;
    }
  }

  input[type="submit"] {
    width: 20px;
    height: 20px;
    border: none;
    background: url(../loupe.svg) no-repeat 50% 50%;
    position: absolute;
    left: 28vw;
    top: 37%;
    cursor: pointer;
    text-indent: -1000em;
  }


  //input:focus > input[type="submit"] {
  //  background: url(../loupeBlue.svg) no-repeat 50% 50%;
  //}

  .history_search {
    padding: 20px;
    width: 30vw;
    height: 237px;
    background: white;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    z-index: 1;
    //cursor: pointer;

    ul {
      text-align: left;
      margin-top: 20px;
      font-size: 0.83vw;
      font-weight: 400;
    }

    ul li {
      margin: 15px 0;
    }

  }

  .history_search::-webkit-scrollbar {
    width: 4px;
    //height: 10px;
  }

  .history_search::-webkit-scrollbar-thumb {
    background: linear-gradient(to bottom right, #4d7fff 0%, #04B0FB 100%);
    border-radius: 5px;
  }

  .history_search_block {
    display: flex;
    justify-content: space-between;
    font-size: 0.83vw;
    font-weight: 400;
    color: #999999;

    :nth-child(1) {
      color: #04B0FB;
      font-weight: 500;
    }

    :nth-child(2) {
      cursor: pointer;
    }
  }

  .search_block {
    //border: 2px solid #04B0FB;
  }

  input::placeholder {
    font-weight: 500;
    font-size: 0.83vw;
    color: #999999;
  }
}

.grath_input {
  flex-grow: 0;

  input {
    width: 14.17vw;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #999999;
  }

  input[type="submit"] {
    left: 12vw;
    top: 41%;
  }
}

.grath_rep {
  text-align: left;
}

.order_data {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 50px;
}

.order_list_ttl {
  font-size: 1.25vw;
  font-weight: 500;
  color: #04B0FB;
  margin-bottom: 23px;
}

.order_list {
  position: absolute;
  background: white;
  top: 5vw;
  right: 9.9vw;
  text-align: left;
  box-shadow: 0 11px 11px 0 rgba(40, 45, 40, 0.09);

  &:before {
    content: '';
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(3, 3, 3, 0.2);
    background-size: cover;
    filter: blur(2px);
  }

}

.order_list_visible {
  position: relative;
  z-index: 10;
  background: #fff;
  padding: 40px;
  width: 30vw;
}

.order_list_pos {
  display: flex;
  justify-content: space-between;
}

.order_close_btn {
  //padding: 15px;
  cursor: pointer;
}

.order_item_pos {
  display: flex;
  margin-bottom: 10px;
}

.order_item {
  width: 25.83vw;
  height: 46px;
  margin-top: 15px;
  cursor: pointer;
}

.order_item_ttl {
  font-size: 0.83vw;
  font-weight: 500;
  color: black;
}

.order_item_st {
  font-size: 0.83vw;
  font-weight: 700;
  color: #04B0FB;
  margin-left: 7px;
}

.order_item_dt {
  font-size: 0.83vw;
  font-weight: 400;
  color: #999999;
}

.order_inf {
  position: absolute;
  background: white;
  width: 576px;
  top: 0;
  right: 0;
  height: 663px;
  text-align: left;
  box-shadow: 0 11px 11px 0 rgba(40, 45, 40, 0.09);
  padding: 40px;
}

.order_list_info {
  color: #999999;
  font-weight: 400;
  font-size: 0.83vw;
  margin-top: 35px;

  li > span:nth-child(2) {
    color: #04B0FB;
    font-weight: 500;
    padding-left: 1px;
  }

  li {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.order_pos_cost {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
  line-height: 20px;
  height: 90px;
  border-bottom: 2px solid #04B0FB;

  span {
    color: #04B0FB;
    font-weight: 500;
  }
}

.order_pos_total_block {
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  margin-top: -7px;
}

.order_total {
  font-size: 1.04vw;
  font-weight: 700;
  color: #030303;
}

.order_total_cost {
  color: #04B0FB;
  font-size: 1.04vw;
  font-weight: 700;
}

.order_disc_cost {
  font-weight: 500;
}

.order_submit {
  width: 9.90vw;
  height: 43px;
  font-size: 0.83vw;
  font-weight: 700;
  color: white;
  background: #04B0FB;
  border: 2px solid #04B0FB;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 20px;
}

.order_close {
  width: 6.61vw;
  height: 43px;
  font-size: 0.83vw;
  font-weight: 700;
  color: #F93F3F;
  background: white;
  border: 2px solid #F93F3F;
  cursor: pointer;
  border-radius: 5px;
}

.actions {
  display: flex;
  gap: 8px;
}


.employee {
  position: relative;

  button {
    //background: #04B0FB;
    background: white;
    cursor: pointer;
    width: 14.17vw;
    height: 2.92vw;
    /* padding: 16px 32px; */
    border-radius: 4px;
    border: 2px solid #04B0FB;
    gap: 8px;
    font-size: 1.04vw;
    //color: white;
    color: #04B0FB;
    font-weight: 700;
    /* margin-right: 1vw; */
    margin: 0.89vw 0 0 1vw;
    //padding-bottom: 10px;
    padding-bottom: 5px;
  }

  img {
    position: relative;
    right: 4px;
    top: 2px;
  }
}

.social {
  display: flex;
  gap: 4px;
  align-items: center;
}

.callBack_btn {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  background: white;
  border: none;
  cursor: pointer;
}

.callBack_title {
  padding-left: 15px;
  font-weight: 500;
  font-size: 0.63vw;
  line-height: 140%;
  letter-spacing: -0.3px;
  color: #999999;
}

.onlineOrder_title {
  padding-left: 12px;
  font-weight: 500;
  font-size: 0.63vw;
  line-height: 140%;
  letter-spacing: -0.3px;
  color: #999999;
}

.callBack_icon {
  padding: 7px 15px 7px 0;
}

.onlineOrder_btn {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  background: #ffffff;
  border: none;
  cursor: pointer;
  margin-right: 0.8vw;
}

.onlineOrder_icon {
  padding: 7px 14px 6px 0;
}

.sms_btn {
  background: #ffffff;
  border-radius: 50%;
  border: none;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 0.8vw;
}

.mail_btn {
  //position: relative;
  //top: 25%;
  background: #ffffff;
  border-radius: 50%;
  border: none;
  width: 28px;
  height: 28px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  margin-right: 1vw;
}

.balance_block {
  //border: 2px solid #04B0FB !important;
  background: #04B0FB;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 15.814%;
  height: 58.34%;
  border-radius: 4px;
  border: 2px;
  gap: 12px;
  margin: 0.89vw 0 0 0;
  justify-content: center;
  //margin-left: 1vw;
}

.balance_title {
  //padding-left: 13px;
  font-weight: 700;
  font-size: 0.83vw;
  line-height: 140%;
  letter-spacing: -0.3px;
  color: white;
}

.balance_amount {
  //padding-left: 50px;
  font-weight: 700;
  font-size: 1.25vw;
  line-height: 120%;
  letter-spacing: -0.4px;
  color: white;
}

.balance_btn {
  background: #ffffff;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  //width: 15px;
  //height: 15px;
  cursor: pointer;
}

.today {
  font-weight: 500;
  font-size: 10px;
  line-height: 120%;
  letter-spacing: -0.4px;
  color: #181819;
}

.dateToday {
  font-weight: 500;
  font-size: 1.04vw;
  line-height: 120%;
  letter-spacing: -0.4px;
  color: black;
  text-transform: capitalize;
  margin: -5px 0 30px 0;
}

.today_block {
  display: flex;
  flex-direction: column;
  margin-bottom: 19px;
  align-items: flex-start;
  padding: 0 50px;
}

.order_block {
  //position: fixed;
  //right: 120px;
  //top: 180px;
  //display: flex;
  //align-items: center;
  //flex-direction: column;
  //gap: 7px;
  //cursor: pointer;
  //z-index: 9999;

}

.order_title {
  font-weight: 500;
  font-size: 1.04vw;
  line-height: 140%;
  letter-spacing: -0.3px;
  color: white;
}

.order_btn {
  align-items: center;
  justify-content: center;
  border: none;
  display: flex;
  cursor: pointer;
  width: 13.13vw;
  height: 6.04vw;
  padding: 12px 0 12px 0;
  border-radius: 4px;
  gap: 17px;
  background: #04B0FB;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);

  img {
    position: relative;
    right: 5px;
  }
}

.grath {
  //width: 831px;
  height: 340px;
  border: 1px solid #e6e7e9;
  border-radius: 8px;
  //margin-bottom: 19px;
  //display: flex;
  //flex-direction: column;
  //align-items: flex-start;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
  margin: 60px 50px 30px 50px;
  //padding: 0 50px;
  background: white;

}

.grath_rep_pos {
  display: flex;
  justify-content: space-between;
  margin: 0 30px;
}

.grath_value {
  font-weight: 700;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: -0.4px;
  color: rgba(24, 24, 25, 0.9);
}

.grath_title {
  font-weight: 500;
  font-size: 1.46vw;
  line-height: 120%;
  letter-spacing: -0.4px;
  color: rgba(24, 24, 25, 0.9);
  margin: 30px 0 10px 35px;
}

.dot {
  fill: #04B0FB;
  stroke: #04B0FB;
}

.grath_date {
  font-weight: 400;
  font-size: 0.83vw;
  line-height: 140%;
  letter-spacing: -0.5px;
  color: rgba(24, 24, 25, 0.42);
  margin-left: 35px;
  margin-bottom: 24px;
}

.calendar {
  margin-bottom: 21px;
  grid-area: calendar;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #e6e7e9;
  border-radius: 12px;
  padding: 15px 20px 7px 21px;
}

.title_block {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.calendar_title {
  font-weight: 500;
  font-size: 0.83vw;
  line-height: 120%;
  letter-spacing: -0.4px;
  color: rgba(24, 24, 25, 0.9);
}

.year_block {
  display: flex;
  align-items: center;
  gap: 7px;
}

.year {
  margin-top: 1px;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: -0.4px;
  color: rgba(24, 24, 25, 0.9);
}

.months {
  display: flex;
  gap: 5px;
}

.months_item {
  border: 1px solid #e6e7e9;
  background: #ffffff;
  border-radius: 100px;
  font-weight: 500;
  font-size: 10px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.3px;
  color: rgba(24, 24, 25, 0.42);
  width: 61px;
  height: 20px;
  cursor: pointer;

  &:hover {
    background: #e8f5e9;
  }
}

.active_month {
  background: #e8f5e9;
  border: none;
  color: #181819;
}

.wrapper_block {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 284px 547px;
  grid-template-rows: 131px repeat(3, 71px);
  grid-template-areas:
    'calendar calendar'
    'lead orders'
    'lead orders'
    'amount orders'
    'amount analytics'
    'clients analytics'
    'clients analytics';
}

.lead {
  grid-area: lead;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //background: #fff8e1;
  border-radius: 8px;

  width: 15.73vw;
  height: 6.04vw;
  //margin-top: 10px;
  background: #DEF3FD;
  //gap: 10px;
  //padding: 10px 0 10px 29px;
  padding: 0.52vw 0 0.52vw 1.51vw;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.04);
}

.lead_title {
  //margin-top: 10px;
  img {
    margin: 0 10px 0 0;
    position: relative;
    top: 2px;
  }
}

.amount_title {
  img {
    margin: 0 10px 0 0;
    position: relative;
    top: 5px;
  }
}

.clients_title {
  img {
    margin: 0 10px 0 0;
    position: relative;
    top: 3px;
  }
}

.lead_title,
.amount_title,
.clients_title {
  font-weight: 400;
  font-size: 0.83vw;
  line-height: 120%;
  align-items: center;
  letter-spacing: -0.4px;
  color: #04B0FB;
}

.lead_amount {
  //margin-top: 5px;
  //margin-bottom: 5px;
  margin-top: 0.2vw;
  margin-bottom: 0.2vw;
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 170%;
  letter-spacing: -0.4px;
  color: black;
}

.check_link {
  font-weight: 400;
  font-size: 0.83vw;
  line-height: 100%;
  align-items: center;
  letter-spacing: -0.3px;
  color: rgba(24, 24, 25, 0.42);
  cursor: pointer;
}

.amount_today {
  width: 15.73vw;
  height: 6.04vw;
  grid-area: amount;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #DEF3FD;
  border-radius: 8px;
  //gap: 10px;
  //padding: 10px 0 10px 29px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.04);
  gap: 0.52vw;
  padding: 0.52vw 0 0.52vw 1.51vw;
}

.today_amount {
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 120%;
  letter-spacing: -0.4px;
  color: black;
}

.clients {
  grid-area: clients;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #e1f5fd;
  border-radius: 8px;
  gap: 0.3vw;
  //padding: 10px 0 16px 29px;
  padding: 0.52vw 0 0.52vw 1.51vw;
  width: 15.73vw;
  height: 6.04vw;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.04);
}

.clients_amount {
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 1.9vw;
  letter-spacing: -0.4px;
  color: black;
}

.orders {
  grid-area: orders;
  border-radius: 12px;
  margin-bottom: 17px;
  margin-left: 19px;
}

.orders_wrapper {
  display: flex;
  flex-direction: column;
  background: #e1f5fd;
  border-radius: 12px;
  align-items: baseline;
  width: 20.52vw;
  gap: 0.24vw;
  height: 6.04vw;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.04);
  position: relative;
}

.image_swiper_button_next {
  position: absolute;
  top: 140%;
  z-index: 10;
  left: 60.4%;
  cursor: pointer;
}

.image_swiper_button_prev {
  position: absolute;
  top: 114%;
  z-index: 10;
  right: 64.4%;
  cursor: pointer;
}

.orders_date {
  font-weight: 400;
  margin-top: 0.36vw;
  margin-left: 1.51vw;
  font-size: 0.83vw;
  line-height: 230%;
  letter-spacing: 0.3px;
  /* text-transform: uppercase; */
  color: #04B0FB;
}

.orders_date {
  img {
    margin: 0 5px 0 0;
    position: relative;
    top: 4px;
  }

}

.orders_inwork {
  margin-bottom: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
}

.orders_title {
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 1.3vw;
  letter-spacing: -0.5px;
  color: black;
}

.orders_amount {
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: white;
  background: #04B0FB;
  border-radius: 5px;
  border: none;
  width: 4.06vw;
  height: 1.93vw;
  position: absolute;
  left: 72%;
}

.orders_check {
  font-weight: 400;
  font-size: 0.83vw;
  line-height: 140%;
  /* letter-spacing: -0.5px; */
  color: #999999;
  cursor: pointer;
  /* margin: 0 0 214px 0; */
  position: relative;
  bottom: 19px;
}

.analytics {
  margin-left: 19px;
  grid-area: analytics;
  background: #ffffff;
  border: 1px solid #e6e7e9;
  border-radius: 8px;
  padding: 20px 41px 41px 41px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 44%;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
  height: 496px;
}

.analytics_title {
  font-weight: 500;
  font-size: 1.46vw;
  line-height: 120%;
  letter-spacing: -0.4px;
  color: rgba(24, 24, 25, 0.9);
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  position: relative;
  right: 7px;

  span {
    margin: 0 0 13px 10px;
  }

  img {

  }
}

.analytics_notifications {
  font-weight: 400;
  font-size: 0.83vw;
  line-height: 140%;
  letter-spacing: -0.5px;
  color: rgba(24, 24, 25, 0.42);
  margin-bottom: 5.5px;
  position: relative;
  left: 68px;
  bottom: 27px;
}

.analytics_item {
  margin: 10px 0;
  background: #F6FAFE;
  display: flex;
  justify-content: space-between;
  height: 72px;
  padding: 12px 13px 0 13px;
  line-height: 34px;
  align-items: center;
}

.analytics_list {
  height: 312px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.report_block {
  background: white;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  //padding: 27px 24px 26px 36px;
  width: 100%;
  margin-bottom: 5px;
  height: 312px;
  overflow-y: auto;
  overflow-x: hidden;
}

.report_text {
  //display: flex;
  //flex-direction: column;
  //gap: 7px;
  text-align: left;
}

.report_title {
  font-weight: 500;
  font-size: 1.04vw;
  line-height: 120%;
  letter-spacing: -0.4px;
  color: #181819;
}

.report_check {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.5px;
  color: rgba(24, 24, 25, 0.42);
  cursor: pointer;
  text-align: left;

}

.planner {
  //border: 1px solid #e6e7e9;
  //border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 35px 45px 35px 45px;
  margin-bottom: 8px;
}

.planner_header {
  display: flex;
  gap: 16px;
  align-items: center;
}

.planner_title {
  font-weight: 500;
  font-size: 1.46vw;
  line-height: 120%;
  letter-spacing: -0.4px;
  color: rgba(24, 24, 25, 0.9);
}

.planner_icon {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.planner_btn {
  background: white;
  //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 198px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid rgba(4, 176, 251, 1);
  font-size: 0.83vw;
  font-weight: 400;
  color: #04B0FB;

  img {
    position: relative;
    right: 7px;
  }
}

.planner_headline {
  display: flex;
  align-items: center;
  padding: 17px 25px;
  border: 1px solid #e6e7e9;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.3px;
  color: rgba(24, 24, 25, 0.7);
  margin-bottom: 9px;
}

.checkbox_icon {
  display: flex;
  width: 7.5%;
}

.headline_date {
  width: 23.5%;
}

.headline_responsible {
  width: 31.5%;
}

.headline_task {
  width: 27%;
}

.headline_status {
  width: 9%;
}

.planner_row {
  padding: 9px 22px 9px 25px;
  border: 1px solid #e6e7e9;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.row_date {
  width: 22.5%;
}

.row_date,
.row_name {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.5px;
  color: rgba(24, 24, 25, 0.9);
}

.row_responsible {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
}

.task_wrapper {
  display: flex;
  justify-content: center;
  margin-left: 10%;
}

.row_task {
  background: rgba(86, 87, 88, 0.12);
  border-radius: 100px;
  padding-left: 5px;
  padding-right: 5px;
  width: fit-content;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.task_text {
  padding-top: 1px;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.3px;
  color: rgba(24, 24, 25, 0.9);
}

.status_wrapper {
  width: 11%;
  margin-left: 35%;
}


.row_status {
  background: #f2e7fc;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 18px;
}

.status_text {
  padding-top: 1px;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.3px;
  color: #8c18e2;
}

.reportday_block {
  display: none;
}

.planner_mobilbtn,
.task_link,
.burger_menu {
  display: none;
}

.planner_wrapper {
  border: none;
  border-radius: 0;
  /* margin-bottom: 50px; */
  width: 55%;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
  background: white;
  height: 496px;
}

.slider_arrow {
  position: relative;
  height: 100%;
  width: 100%;
}

.arrow_btn {
  top: 45%;
  z-index: 99;
  background: #4a4de6;
  cursor: pointer;
}

.next {
  float: right;
}


.MuiDataGrid-columnSeparator {
  visibility: hidden;
  display: none;
}

//////////chart tooltip/////

.tooltip_block {
  background: #ffffff;
  border: 2px solid #e8f5e9;
  display: flex;
  gap: 12px;
  flex-direction: column;
  border-radius: 8px;
  padding: 11px 11px;
  width: 283px;
  height: 160px;
}

.tooltip_title {
  display: flex;
  align-items: center;
  gap: 9px;
}

.tooltip_amount {
  //margin-right: 105px;
  display: flex;
  justify-content: start;
}

.tooltip_total {
  font-weight: 500;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.4px;
  color: black;

}

.tooltip_persent {
  font-weight: 500;
  font-size: 0.83vw;
  line-height: 120%;
  letter-spacing: -0.4px;
  color: #026457;
  margin-left: 4px;
  margin-top: 10px;
}

.tooltip_day {
  font-weight: 400;
  font-size: 0.83vw;
  line-height: 140%;
  letter-spacing: -0.3px;
  color: #999999;
}

.tooltip_date {
  font-weight: 400;
  font-size: 0.83vw;
  line-height: 140%;
  letter-spacing: -0.3px;
  color: #999999;
}

.tooltip_section {
  display: flex;
  //gap: 15px;
  justify-content: space-between;
}

.tooltip_sectionitem {
  display: flex;
  flex-direction: column;
}

.tooltip_sectiontitle {
  font-weight: 500;
  font-size: 0.83vw;
  line-height: 140%;
  letter-spacing: -0.3px;
  color: #04B0FB;
}

.tooltip_sectiontotal {
  font-weight: 500;
  font-size: 0.83vw;
  line-height: 120%;
  letter-spacing: -0.4px;
  margin-top: 5px;
  color: black;
  //margin-right: 5px;
  position: relative;
  left: 3px;
}

.content_wrapper {

}

.wrapper_block_pos {
  display: flex;
  margin: 0 0 70px 0;
  padding: 0 50px;
}

///////////////////////////Mobil///////////////////////////////////////////////////

@media (max-width: 800px) {
  body {
    overflow-x: hidden;
    width: 100%;
  }
  .content_wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .container {
    width: 100%;
    margin-top: 0;

  }


  .header {
    background: linear-gradient(90deg, #c5d25d 49.48%, rgba(197, 210, 93, 0.75) 100%);
    padding: 19px 15px 13px;
  }

  .actions {
    display: none;
  }

  .balance_block {
    margin-left: 6px;
  }

  .today_block {
    margin-bottom: 29px;
    margin-top: 25px;
  }

  .today,
  .dateToday {
    font-size: 14px;
  }

  .grath {
    display: none;
  }

  .reportday_block {
    display: flex;
    gap: 6px;
    flex-direction: column;
    border: 1px solid #e8f5e9;
    border-radius: 8px;
    padding: 11px 11px;
    width: 190px;
    margin-bottom: 60px;
  }

  .reportday_title {
    display: flex;
    align-items: center;
    gap: 9px;
  }

  .reportday_total {
    font-weight: 400;
    font-size: 22px;
    line-height: 120%;
    letter-spacing: -0.4px;
    color: #4caf50;
  }

  .reportday_persent {
    font-weight: 700;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: -0.4px;
    color: #4caf50;
    margin-left: 4px;
  }

  .reportday_day {
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: -0.3px;
    color: #000000;
  }

  .reportday_date {
    font-weight: 500;
    font-size: 10px;
    line-height: 140%;
    letter-spacing: -0.3px;
    color: rgba(24, 24, 25, 0.42);
  }

  .reportday_section {
    display: flex;
    gap: 15px;
  }

  .reportday_sectionitem {
    display: flex;
    flex-direction: column;
  }

  .reportday_sectiontitle {
    font-weight: 500;
    font-size: 10px;
    line-height: 140%;
    letter-spacing: -0.3px;
    color: rgba(24, 24, 25, 0.42);
  }

  .reportday_sectiontotal {
    font-weight: 700;
    font-size: 8px;
    line-height: 120%;
    letter-spacing: -0.4px;
    color: #4caf50;
  }

  .wrapper_block {
    margin-bottom: 20px;
    // display: flex;
    // gap: 20px;
    // margin-bottom: 20px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 136px 254px 208px 208px 136px 146px;
    grid-row-gap: 40px;
    grid-template-areas:
      'amount'
      'calendar'
      'orders'
      'analytics'
      'lead'
      'clients';
  }

  .amount_today {
    height: 142px;
    margin: 0;
    grid-area: amount;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #e8f5e9;
    border-radius: 8px;
    gap: 10px;
    padding: 10px 0 10px 29px;
  }

  .calendar {
    margin: 0;
    grid-area: calendar;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid #e6e7e9;
    border-radius: 12px;
    padding: 15px 20px 20px 21px;
  }

  .title_block {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 23px;
  }

  .year_block {
    display: flex;
    align-items: center;
    gap: 27px;
  }

  .year {
    margin-top: 1px;
    font-weight: 500;
    font-size: 0.83vw;
    line-height: 120%;
    letter-spacing: -0.4px;
    color: rgba(24, 24, 25, 0.9);
  }

  .arrow_left,
  .arrow_right {
    width: 9px;
    height: 16px;
  }

  .months {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

  .months_item {
    margin-bottom: 19px;
    border: 1px solid #e6e7e9;
    background: #ffffff;
    border-radius: 100px;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.3px;
    color: rgba(24, 24, 25, 0.42);
    width: 95px;
    height: 25px;
  }

  .orders {
    margin: 0;
  }

  .analytics {
    margin: 0;
    grid-area: analytics;
    background: #ffffff;
    border: 1px solid #e6e7e9;
    border-radius: 8px;
    padding: 21px 22px 41px 20px;
    display: flex;
    flex-direction: column;
  }

  .planner_wrapper {
    border: 1px solid #e6e7e9;
    border-radius: 16px;
  }

  .planner {
    border: none;
    border-radius: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0 0 14px;
    margin-bottom: 0;
  }

  .planner_header {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .planner_title {
    font-weight: 500;
    font-size: 0.83vw;
    line-height: 120%;
    letter-spacing: -0.4px;
    color: rgba(24, 24, 25, 0.9);
  }

  .planner_icon {
    display: none;
  }

  .planner_btn {
    display: none;
  }

  .planner_headline {
    display: flex;
    align-items: center;
    padding: 34px 27px 26px 28px;
    border: none;
    border-radius: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: -0.3px;
    color: rgba(24, 24, 25, 0.7);
    margin-bottom: 0;
  }

  .headline_status,
  .row_status,
  .status_dots,
  .checkbox_icon,
  .status_wrapper {
    display: none;
  }

  .headline_date {
    width: 22.5%;
  }

  .headline_responsible {
    width: 50.5%;
  }

  .headline_task {
    width: 27%;
    text-align: end;
  }

  .headline_status {
    width: 9%;
  }

  .planner_row {
    padding: 7px 6px 9px 21px;
    border: 1px solid #e6e7e9;
    border-radius: 8px;
    display: flex;
    align-items: center;
    margin: 0 5px 24px 5px;
  }

  .row_date {
    width: 22.5%;
  }

  .row_date,
  .row_name {
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: -0.5px;
    color: rgba(24, 24, 25, 0.9);
  }

  .row_responsible {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .task_link {
    display: block;
    font-weight: 300;
    font-size: 10px;
    line-height: 140%;
    letter-spacing: -0.3px;
    color: rgba(24, 24, 25, 0.42);
    cursor: pointer;
  }

  .task_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .row_task {
    background: rgba(86, 87, 88, 0.12);
    border-radius: 100px;
    padding-left: 5px;
    padding-right: 5px;
    width: fit-content;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .task_text {
    padding-top: 1px;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: -0.3px;
    color: rgba(24, 24, 25, 0.9);
  }

  .row_status {
    background: #f2e7fc;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 18px;
  }

  .status_text {
    padding-top: 1px;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: -0.3px;
    color: #8c18e2;
  }

  .planner_mobilbtn {
    display: block;
    width: 100%;
    height: 59px;
    font-weight: 400;
    font-size: 0.83vw;
    line-height: 140%;
    letter-spacing: -0.5px;
    color: #ffffff;
    background: #bbca43;
    border: 1px solid #e6e7e9;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .burger_menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    gap: 6px;
    z-index: 11;
  }


  .active:nth-of-type(1) {
    transform: translateX(85vw) rotate(45deg) translate(10px, 5px);
    width: 25px;
    z-index: 2;
    transition: all 1s;
  }

  .active:nth-of-type(2) {
    opacity: 0;
    pointer-events: none;
  }

  .active:nth-of-type(3) {
    transform: translateX(85vw) rotate(-45deg) translate(6px, -2px);
    width: 25px;
    z-index: 2;
    transition: all 1s;
  }

  .burger_line {
    height: 2px;
    width: 28px;
    background-color: #e8f5e9;
    top: 13px;
    border-radius: 1px;
  }

  .burger_title {
    font-weight: 500;
    font-size: 10px;
    line-height: 120%;
    letter-spacing: -0.4px;
    color: #e8f5e9;
    position: absolute;
    top: 39px;
  }

  .order_block {
    position: fixed;
    left: 286px;
    top: 400px;
    flex-direction: column-reverse;
    z-index: 10;
  }
}

@media (max-width: 1440px) and (min-width: 801px) {

  .container {
    //margin-left: 99px;
  }

  .order_block {
    right: 50px;
  }


}


@media (max-width: 800px) {
  .order_block {
    right: 10px;
    left: 80%;
  }
  .row_responsible {
    img {
      display: none;
    }
  }
  .MuiDataGrid-columnHeaders {
    border: none !important;
  }
}

@media (max-width: 412px) {
  .months_item {
    font-size: 10px;
    width: 90px;
  }
  .orders_title {
    font-size: 21px;
  }
  .planner_mobilbtn {
    width: 100%;
  }

  .MuiDataGrid-columnHeaders {
    border: none !important
  }
}

.big_order_list > li:nth-child(odd) {
  background: #F6FAFE;
}

.big_order_list_block {
  background: white;

  //box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

.big_order_block {
  margin: 35px 50px 110px 50px;
}

.big_order_pos {
  //display: flex;
  //justify-content: space-between;
  //align-items: center;
}

.big_order_item {
  height: 88px;
  border: 1px solid #E6F0EF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  font-size: 1.04vw;
}

.menu_title {
  font-weight: 500;
}

.menu_date {
  font-weight: 400;
  color: #999999;
}

.big_order_btns_gr {
  justify-content: end;
  display: flex;
  margin-top: 30px;
}

.big_order_btn {
  width: 24px;
  height: 24px;
  background: none;
  color: #999999;
  font-size: 0.83vw;
  font-weight: 400;
  border-radius: 5px;

  &:hover {
    background: #04B0FB;
    color: white;

    svg {
      fill: white;
    }

  }

  &:focus {
    background: #04B0FB;
    color: white;

    svg {
      fill: white;
    }

  }

  svg {
    fill: #04B0FB;
  }
}

.active_btn {
  background: #04B0FB;
  color: white;
}

.disabled_btn {
  &:hover {
    background: none;
    fill: #CCCCCC;
  }

  svg {
    fill: #CCCCCC;

  }
}

.personal_page_menu {
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  position: relative;
}

.personal_page_menu_sub {
  width: 16.82vw;
  min-height: 5.31vw;
  background: #DEF3FD;
  color: #04B0FB;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
  text-align: left;
  //padding: 15px;
  padding: 0.63vw 1vw;
  /* line-height: 20px; */
  gap: 0.9vw;
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 1vw;

  div:nth-child(1) > span > span{
    position: absolute;
    top: 17.17%;
  }

  div:nth-child(2) {
    color: black;
    font-size: 1.5vw;
    padding-top: 4px;
  }

}

.personal_page_menu_btn {
  align-items: center;
  justify-content: center;
  border: none;
  display: flex;
  cursor: pointer;
  width: 16.82vw;
  height: 5.31vw;
  padding: 12px 0 12px 0;
  border-radius: 4px;
  gap: 12px;
  background: #04B0FB;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
}

.personal_page_menu_s_pos {
  display: flex;
  justify-content: space-between;
  //flex-direction: column;
  span {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.deb_empl_itm {
  //display: flex;
  //padding: 40px;

  //width: 82.92vw;
  //height: 3.54vw;
}

.debtor_block {
  /* padding: 30px; */
  margin: 50px;
  background: #FFFFFF;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
}

.debtor_subblock {
  display: flex;
  justify-content: space-between;
  //padding: 0 50px 50px 50px;
  padding: 50px 50px 0 50px;

  > :nth-child(1) {
    font-weight: 500;
    font-size: 1.46vw;
    display: flex;
    //align-items: center;
    img {
      position: relative;
      bottom: 43%;
    }
  }

  > div:nth-child(1) {
    position: relative;
    top: 10px;
  }

  > div:nth-child(2) {
    position: relative;
    top: 7px;
  }

  :nth-child(2) {
    display: flex;
    //align-items: center;
    div {
      font-weight: 400;
      font-size: 0.83vw;
      padding: 0 20px;
      height: 2.08vw;
      align-items: center;
      display: flex;
      position: relative;
      bottom: 8%;
      //:nth-child(1) {
      //  width: 5.26vw;
      //  height: 2.08vw;
      //}
      margin: 0 6px;
    }

    :nth-child(1) {
      //color: white;
      //background: #04B0FB;
      border-radius: 5px;
      font-weight: 400;
    }

    :nth-child(2), :nth-child(3), :nth-child(4), {
      border-radius: 5px;
      font-weight: 400;
    }
  }
}


.personal_input1 {
  input {
    width: 25.83vw;
    height: 2.08vw;
    outline: none;
    border-radius: 5px;
    border: 1px solid;
  }

  input[type="submit"] {
    left: 24vw;
    top: 23%;
  }
}

.personal_input2 {


  input {
    border: 1px solid #999999;
    width: 18.44vw;
    height: 2.08vw;
    margin-top: 13px;
    margin-left: 40%;
    outline: none;
  }

  input[type="submit"] {
    left: 13.8vw;
    top: 20%;
  }
}

.activeBtnSP {
  color: white;
  background: #04B0FB;

  svg {
    fill: white;
    margin-left: 10px;
    width: 22px;
  }
}

.notActiveBtnSP {
  border: 1.5px solid #04B0FB;
  color: #04B0FB;

  svg {
    fill: #04B0FB;
    margin-left: 10px;
    width: 22px;
  }

}

.debtors_title div {
  display: flex;
  //width: 100%;
  height: 3.54vw;
  background: #04B0FB;
  color: white;
  font-size: 1vw;
  align-items: center;
  border-radius: 5px 5px 0 0;

  svg {
    fill: white;
    position: absolute;
    //margin-left: 7px;
    //bottom: 1.1vw;
  }

}

.big_order_list input[type=checkbox] {
  //background: #04B0FB;
  //accent-color: #04B0FB;
  //border: 1px solid white;
  width: 20px;
  height: 20px;
}

//.debtors_title_checkbox_custom,
//.debtor_item_checkbox_custom,
//.resilution_item_id_checkbox {
//  position: absolute;
//  z-index: -1;
//  opacity: 0;
//
//  + label {
//    display: inline-flex;
//    align-items: center;
//    user-select: none;
//  }
//
//  + label::before {
//    content: '';
//    display: inline-block;
//    width: 1em;
//    height: 1em;
//    flex-shrink: 0;
//    flex-grow: 0;
//    border: 1px solid white;
//    border-radius: 0.25em;
//    //margin-right: 0.5em;
//    background-repeat: no-repeat;
//    background-position: center center;
//    background-size: 50% 50%;
//  }
//
//  &:checked + label::before {
//    //border-color: #04B0FB;
//    background-color: #04B0FB;
//    background-image: url("../tick.svg");
//    //background-image: url("../tickOK.svg");
//    background-repeat: no-repeat;
//    background-position: center center;
//    background-size: 70%;
//  }
//}
//
//.debtor_item_checkbox_custom {
//  + label::before {
//    border: 1px solid black;
//  }
//
//  &:checked + label::before {
//    border-color: #04B0FB;
//  }
//}


.debtors_column_position {
  display: flex;
  width: 82.92vw;
  height: 3.54vw;
  align-items: center;

  svg {
    fill: #999999;
  }
}

.debtors_columns_position,
.debtors_column_position {
  //padding: 0 3%;
  font-size: 1vw;
  width: 100%;

  div:nth-child(1) {
    //margin-left: 3%;
    //padding: 0 1.7vw;
    justify-content: center;
    width: 7%;
    cursor: pointer;
  }

  div:nth-child(2) {
    //margin-left: 11%;
    width: 18%;
    justify-content: center;
  }

  div:nth-child(3) {
    //margin-left: 25%;
    width: 40%;
    justify-content: center;
    gap: 5px;
    position: relative;
  }

  div:nth-child(4) {
    //margin-left: 11%;
    justify-content: center;
    width: 12%;
    gap: 5px;
  }

  div:nth-child(5) {
    //margin-left: 8%;
    justify-content: center;
    width: 12%;
  }

  div:nth-child(6) {
    //margin-left: 3%;
  }

  div:nth-child(7) {
    //margin-left: 1%;
    justify-content: center;
    width: 4%;
  }

  div:nth-child(8) {
    //margin-left: 2%;
    width: 7%;
    justify-content: center;
  }
}

.debtor_hidden_container_parent {
  position: relative;
  top: 9%;

  div:nth-child(1) {
    svg {
      fill: white;
      position: relative;
      left: 1260%;
      bottom: 12px;
    }
  }
}

.debtor_hidden_container_child {
  position: absolute;
  text-align: left;
  background-color: white;
  color: #999999;
  font-size: 1vw;
  font-weight: 400;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
  width: 8.81vw !important;
  /* height: 10.25vw; */
  line-height: 2.44vw;
  padding: 0 15px;
  z-index: 1;
  top: 21px;
  left: -22px;

  span {
    margin-left: 6px;
  }
}

.big_order_btns_gr.debtor {
  /* padding-bottom: 30px; */
  margin: 0;
  position: relative;
  top: 40px;
}

.debt_filter {
  position: relative;

  div:nth-child(1) {
    svg {
      margin-left: 5px;
      bottom: 1.1vw;
    }
  }

  div:nth-child(2) {
    border-radius: 4px;
    position: absolute;
    top: 81%;
    width: 7.38vw;
    height: 5.25vw;
    background: #FFFFFF;
    color: #999999;
    box-shadow: 0px 4px 12px 0px #00000014;
    z-index: 1;

    ul li {
      display: inline-block;
      cursor: pointer;
      width: 100%;
      height: 2.75vw;
      font-size: 1vw;
      /* align-items: center; */
      /* vertical-align: 0px; */
      //line-height: 2.6vw;
      &:nth-child(1) span {
        position: relative;
        top: 1vw;

        svg {
          top: 0.2vw;
          position: relative;
        }
      }

      &:nth-child(2) span {
        position: relative;
        top: 0.3vw;

        svg {
          top: 0.2vw;
          position: relative;
        }
      }
    }

  }

}

.add_staff_block {
  position: absolute;
  top: 114%;
  z-index: 1;
  background: white;
  width: 18.13vw;
  height: 15.25vw;
  font-size: 1vw;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 12px 0 #00000014;
  font-weight: 400;
  border-radius: 4px;

  ul li {
    color: #04B0FB;
    display: flex;
    width: 13.63vw;
    height: 2.75vw;
    cursor: pointer;
    background: #DEF3FD;
    border-radius: 4px;
    /* padding: 0.6vw; */
    margin: 27px 0;

    span {
      text-align: center;
      align-items: center;
      display: flex;
      margin-left: 0.7vw;
      gap: 5px;
    }
  }
}

.staff_debt_limit_text {
  border: none;
  background: #DEF3FD;
  border-bottom: 1px solid #04B0FB;
  padding-left: 5px;
  outline: 0;
  width: 100%;
  font-size: 1vw;

  &::placeholder{
    color: #999999;
  }
}

.staff_debt_limit_submit {
  width: 20px;
  height: 20px;
  border: none;
  background: url(../bedtLimitBtn.svg) no-repeat 50% 50%;
  position: absolute;
  left: 35.5vw;
  top: 54%;
  cursor: pointer;
  background-color: #04B0FB;
  text-indent: -9999px;
  border-radius: 4px;
}

.list_debtors {
  //position: absolute;
  position: relative;
  display: flex;
  z-index: 10;
  left: 21.5%;
}

.debtors_list_position {
  //display: flex;
  height: 20.19vw !important;
  width: 37.50vw !important;
  background-color: white;
  padding: 2vw 3.75vw;
  border-radius: 0 0 5px 5px;
  //flex-direction: column;
  position: absolute;

  > div:nth-child(1) {
    justify-content: space-between;
    display: flex;
    font-size: 1.5vw;
    margin-bottom: 1.1vw;
  }


}

.debtors_list_position_inside {
  font-size: 1vw;

  ul {
    display: flex;

    li {
      //width: 33%;
      height: 2.19vw;
    }
  }

  ul li:nth-child(1) {
    width: 7vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ul li:nth-child(2) {
    width: 19vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ul li:nth-child(3) {
    width: 10vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > div {
    max-height: 211px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.list_debtors_title {
  color: #04B0FB;
}

.list_debtors_hidden {
  position: absolute;
  z-index: 4;
}

.list_debtors_hidden_block {

  &::before {
    content: '';
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(3, 3, 3, 0.2);
    background-size: cover;
    filter: blur(2px);
  }
}

#debtors_ttl_id_checkbox::before  {
  background: #04B0FB!important;
  border: 2px solid white!important;
  content: "\f00c";
  font-size: 15px;
  color: transparent !important;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 5px;
}
#debtors_ttl_id_checkbox:checked:before {
  background-color: #04B0FB;
  background-image: url("../flagOK.svg")!important;
  border: none;
}

