//input[type="checkbox"] {
//  position: absolute;
//  z-index: -1;
//  opacity: 0;
//
//  + label {
//    display: inline-flex;
//    align-items: center;
//    user-select: none;
//  }
//
//  + label::before {
//    content: '';
//    display: inline-block;
//    width: 1em;
//    height: 1em;
//    flex-shrink: 0;
//    flex-grow: 0;
//    border: 1px solid white;
//    border-radius: 0.25em;
//    //margin-right: 0.5em;
//    background-repeat: no-repeat;
//    background-position: center center;
//    background-size: 50% 50%;
//  }
//
//  &:checked + label::before {
//    //border-color: #04B0FB;
//    background-color: #04B0FB;
//    //background-image: url("");
//    background-repeat: no-repeat;
//    background-position: center center;
//    background-size: 70%;
//  }
//}




input[type=checkbox] {
  -moz-appearance:none;
  -webkit-appearance:none;
  -o-appearance:none;
  outline: none;
  content: none;
}

input[type=checkbox]:before {
  //font-family: "FontAwesome";
  content: "\f00c";
  font-size: 15px;
  color: transparent !important;
  background: white;
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid black;
  border-radius: 5px;
  //margin-right: 7px;
}

input[type=checkbox]:checked:before {
  padding: 0 0 0 2px;
  //content: "\2713";
  //background-image: url("flagOK.svg");
  background-image: url("./../../flagOK.svg");
  font-size: 21px;
  background-color: #04B0FB;
  //background: url("src/assets/tickOK.svg");
  //color: white !important;
  border: none;
}


