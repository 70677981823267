.calendar-multi-date-picker {
  background: var(--white);
  border: 1px solid var(--light-border);
  border-radius: 4px;
  box-shadow: none;
  z-index: 0 !important;

  .rmdp-calendar {
    padding: 28px;

    .rmdp-header {
      margin-bottom: 32px;
      margin-top: 0;
      padding: 0 8px;

      .rmdp-header-values {
        span {
          padding: 0;
          font-family: Inter, sans-serif;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          color: var(--main-black);
        }
      }

      .rmdp-arrow-container {
        display: flex;
        align-items: center;
        margin: 0;
        border-radius: 0;

        &:hover {
          background: var(--white);
          box-shadow: none;
        }
      }

      .rmdp-arrow {
        height: 10px;
        width: 10px;
        margin: 0;
        padding: 0;
        border: 0;
        border-right: 2px solid var(--light-blue);
        border-bottom: 2px solid var(--light-blue);
      }
    }

    .rmdp-day-picker {
      padding: 0;

      .rmdp-week + .rmdp-week {
        margin-top: 20px;
      }

      .rmdp-week {
        column-gap: 12px;

        &:first-child {
          margin-bottom: 16px;
        }

        &:nth-child(2) {
          margin-top: 0;
        }

        .rmdp-week-day {
          width: fit-content;
          height: 19px;
          color: var(--main-gray);
          border-radius: 4px;
          font-family: Inter, sans-serif;
          font-size: 16px;
          line-height: 19px;
          font-weight: 500;
          min-width: 44px;
        }

        .rmdp-day {
          height: 44px;
          width: 44px;

          &:hover {
            .sd {
              background-color: transparent;
              border: 1px solid var(--light-blue);
              color: var(--main-black);
              border-radius: 4px;
            }
          }

          &.rmdp-today {
            &:hover {
              .sd {
                background-color: transparent;
                border: 1px solid var(--light-blue);
                color: var(--main-black);
                border-radius: 4px;
              }
            }

            .sd {
              background-color: var(--white);
              color: var(--light-blue);
              border: 1px solid var(--light-blue);
              border-radius: 4px;
            }
          }

          &.rmdp-selected {
            .sd {
              background-color: var(--light-blue);
              color: var(--white);
              border-radius: 4px;
            }
          }
        }

        .sd {
          height: 44px;
          width: 44px;
          border-radius: 4px;
          position: static;
          box-shadow: none;

          font-size: 16px;
          line-height: 19px;
          font-weight: 500;
          font-family: Inter, sans-serif;

          color: var(--main-gray);
          background-color: var(--light-blue-gray);
        }
      }
    }
  }
}
