
input[type=checkbox] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  outline: none;
  content: none;
  //position: absolute;
}

input[type=checkbox]:before {
  //font-family: "FontAwesome";
  content: "\f00c";
  //font-size: 21px;
  color: transparent !important;
  background: white;
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid black;
  border-radius: 5px;
  //margin-right: 7px;
  //position: relative;
}

input[type=checkbox]:checked:before {
  padding: 0 0 0 2px;
  //content: "\2713";
  font-size: 21px;
  background-color: #04B0FB;
  //background: url("src/assets/tickOK.svg");
  //color: white !important;
  border: none;
  outline: none;

  //position: relative;
}

.container {
  //max-width: 1920px;
  max-width: 100%;
  background: rgb(246, 250, 254);
  //margin: 0 0 0 5.21vw;
  padding: 0 50px;
  padding-bottom: 50px;
}

//.today_block {
//  margin: 0;
//}

.back_to_list {
  cursor: pointer;
  float: left;
  margin-top: -19px;
  position: relative;
  bottom: 1.5vw;

  a {
    color: #04B0FB;
    font-weight: 500;
    font-size: 0.83vw;

    svg {
      margin-right: 10px;
    }
  }
}

.cleaner_card_block {
  box-shadow: 0 4px 12px 0 #00000014;
  background-color: white;
  border-radius: 4px;
  padding: 40px;
  margin-top: 3vw;

}

.cleaner_card_data {
  display: flex;
  margin-bottom: 60px;
}

.cleaner_photo {
  width: 13.75vw;
  height: 15.78vw;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #E6F0EF;
  box-shadow: 0 4px 12px 0 #00000014;
  padding: 1.5vw;

  div:nth-child(1) img {
    width: 9.90vw;
    height: 9.90vw;
    margin-bottom: 1vw;
  }

  div:nth-child(2) {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    svg {
      width: 1.46vw;
      height: 1.46vw;
    }

    div:nth-child(2) {
      font-size: 1.25vw;
      font-weight: 600;


    }
  }

}

.cleaner_info {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 2vw;
  padding-top: 3vw;
  gap: 1.7vw;
}

.cleaner_info_name {
  display: flex;
  width: 100%;
  justify-content: space-between;

  > div:nth-child(1) {
    font-size: 1.67vw;
    font-weight: 700;

    svg {
      margin-right: 0.52vw;
    }
  }

  > div:nth-child(2) {
    font-size: 0.83vw;
    display: flex;
    gap: 1.5vw;
    align-items: self-start;
    position: relative;

    svg {
      margin: 0 0.47vw;
    }


  }

  > div:nth-child(3) {
    display: flex;
    gap: 1vw;
    position: relative;
  }
}

.cleaner_info_name_number {
  position: relative;
  bottom: 0.2vw;

  svg {
    width: 1.04vw;
  }
}

.cleaner_info_name_mail {
  position: relative;
  bottom: 0.2vw;

  svg {
    width: 1.04vw;
  }
}

.info_options_about_cleaner {
  display: flex;
  gap: 1.5vw;

  > div:nth-child(1),
  > div:nth-child(2) {
    font-size: 1.04vw;
    font-weight: 600;
    display: flex;
    align-items: center;

    svg {
      margin-right: 7px;
    }
  }

  > div:nth-child(3),
  > div:nth-child(4) {
    font-size: 0.83vw;
    display: flex;
    align-items: center;
  }
}

.cleaner_info_name_select {
  svg {
    width: 1.15vw;
    height: 1.15vw;
  }
}

.data_about_cleaner {
  color: #999999;
  font-size: 0.83vw;
  text-align: left;
  line-height: 1.5vw;

  span {
    color: black;
    font-weight: 500;
    margin-left: 5px;
  }

  ul li:nth-child(4) div {
    display: flex;
    align-items: center;
  }
}

.personal_data_title {
  display: flex;
  justify-content: space-between;
  position: relative;

  svg {
    position: absolute;
    top: 1.3vw;
    right: 0;
  }
}

.personal_data_block {
  margin-top: 100px;
  border-bottom: 1.5px solid #04B0FB;
  margin-bottom: 1.7vw;

  > div > span {
    font-size: 1.46vw;
    font-weight: 500;
    //float: left;
    justify-content: left;
    display: flex;
    line-height: 4.5vw;
  }
}

.personal_data {
  display: flex;
  padding-bottom: 30px;

  ul li {
    color: #999999;
    font-size: 0.83vw;
    text-align: left;
    line-height: 2vw;
  }

  div:nth-child(1) ul li,
  div:nth-child(3) ul li {
    width: 8.3vw;
  }

  div:nth-child(4) ul li {
    position: relative;

    svg {
      position: absolute;
      left: 9.5vw;
      top: 0.3vw;
      width: 1.46vw;
      height: 1.46vw;

    }
  }

  div:nth-child(3) ul li {
    margin-left: 5vw;
  }

  div:nth-child(3) ul li:nth-child(4) div {
    display: flex;
    align-items: center;
  }

  div:nth-child(3) ul li:nth-child(4) {
    width: 21vw;
    position: absolute;
  }

  span {
    color: black;
    font-size: 0.83vw;
    font-weight: 500;
  }

}

.documents_blocks {
  display: flex;
  flex-direction: column;
  gap: 1.8vw;
  margin-top: 60px;
  margin-bottom: 70px;
}

.documents_blocks_official {
  display: flex;
  justify-content: space-between;
  font-size: 0.83vw;
  position: relative;
  text-align: left;

  div ul li:nth-child(1) {
    margin-top: -0.6vw;
    line-height: 2.5vw;
  }

  div:nth-child(1) > ul li:nth-child(2) input[type=number]::-webkit-outer-spin-button,
  div:nth-child(1) > ul li:nth-child(2) input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  div:nth-child(1) > ul li:nth-child(2) input[type='number'] {
    -moz-appearance: textfield;
  }

  div:nth-child(1) > ul li:nth-child(2) input[type=number] {
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
  }

  div:nth-child(1) ul > li:nth-child(1) {
    display: flex;
    justify-content: space-between;
  }

  div:nth-child(4) ul > li:nth-child(1) {
    display: flex;
    justify-content: space-between;

    div:nth-child(2) {
      color: #F93F3F;
      font-size: 1.04vw;
      font-weight: 700;
    }
  }

  svg {
    position: relative;
    top: 0.3vw;
  }

  div:nth-child(1) ul li:nth-child(2),
  div:nth-child(3) ul li:nth-child(2) {
    //width: 11.56vw;
    height: 1.82vw;
    border-radius: 4px !important;
    background-color: #FFFFFF;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  button {
    width: 11.56vw;
    height: 1.82vw;
    border-radius: 4px;
    background-color: #FFFFFF;
  }

  span {
    color: #04B0FB;
  }

  div {
    background-color: #DEF3FD;
    border-radius: 4px;
  }

  > div:nth-child(1),
  > div:nth-child(2),
  > div:nth-child(3),
  > div:nth-child(4) {
    width: 15.5vw;
    box-shadow: 0 4px 12px 0 #0000000A;
  }

  > div:nth-child(5) {
    width: 16.61vw;
    box-shadow: 0 4px 12px 0 #0000000A;
  }

  > div:nth-child(1),
  > div:nth-child(2),
  > div:nth-child(3),
  > div:nth-child(4),
  > div:nth-child(5) {
    height: 6.04vw;
    box-shadow: 0 4px 12px 0 #0000000A;
    position: relative;
    padding: 1.04vw 1.67vw;
  }

  > div:nth-child(1) {
    padding-right: 2.5%;
  }

  > div:nth-child(4) {
    padding-right: 2.7%;
  }
}

.documents_blocks_official_comment {
  display: flex;
  justify-content: space-between;
  font-size: 0.83vw;
  position: relative;
  text-align: left;

  span {
    color: #04B0FB;
  }

  button {
    width: 11.56vw;
    height: 1.82vw;
    border-radius: 4px;
    background-color: #FFFFFF;
  }

  div {
    background-color: #DEF3FD;
    border-radius: 4px;
  }

  > div:nth-child(1),
  > div:nth-child(2) {
    width: 15.5vw;
    box-shadow: 0 4px 12px 0 #0000000A;
  }

  > div:nth-child(3) {
    width: 59.8%;
    box-shadow: 0 4px 12px 0 #0000000A;
    //padding: 0!important;
    display: flex;
  }

  div ul li:nth-child(1) {
    margin-top: -0.6vw;
    line-height: 2.5vw;

    padding-right: 3%;
  }

  > div:nth-child(1),
  > div:nth-child(2),
  > div:nth-child(3) {
    height: 6.04vw;
    box-shadow: 0 4px 12px 0 #0000000A;
    position: relative;
    padding: 1.04vw 1.67vw;
  }

  > div:nth-child(2) {
    background-color: #F6FAFE;
    position: relative;

    div {
      background-color: #F6FAFE;
    }

    button {
      color: #CCCCCC;
    }

    input[type=checkbox] {
      //float: right;
      //margin-top: 6.5%;
    }

    > ul {
      //background: #F6FAFE;

      li:nth-child(1) {
        display: flex;
        //background: #F6FAFE;
        justify-content: space-between;
      }
    }
  }

  > div:nth-child(3) div {

  }

}

.comission_input {
  outline: none;
  background-color: #DEF3FD;
  border: none;
  border-bottom: 1px solid #04B0FB;
  width: 100%;
  position: relative;
  font-size: 0.83vw;
  padding-bottom: 7px;
  padding-left: 8px;
  margin-top: 0.45vw;

  ::placeholder {
    font-size: 0.83vw;
    //font-size: 16px;
  }
}

.comission_submit {
  width: 1.04vw;
  height: 1.04vw;
  text-indent: -9999em;
  background-color: #04B0FB;
  background-image: url(./../../tick.svg);
  //background-image: url(./../../tickOK.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  outline: none;
  border: none;
  border-radius: 4px;
  position: absolute;
  bottom: 1.5vw;
  left: 82%;
}

.comission_input_second {
  width: 81%;
  margin-right: 1.7vw;
}

.comission_submit_second {
  width: 6.98vw;
  height: 1.82vw;
  background-color: white;
  color: black;
  border: none;
  outline: none;
  border-radius: 4px;
}

.requisites_block {
  position: absolute;
  background-color: white !important;
  //border-radius: 4px;
  border: 1px solid #DEF3FD;
  height: 13.75vw !important;
  //width: 6.04vw!important;
  width: 100%;
  z-index: 1;
  padding: 1.56vw 1.25vw;
  font-size: 0.83vw;
  left: 0;
  top: 0;

  div {
    background-color: white !important;
  }

  ul li {
    line-height: 1.2vw !important;
    margin-bottom: 0.7vw;

    > div:nth-child(1) {
      color: #999999;
    }

  }

  ul li:nth-child(1) {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  ul li:nth-child(1) > span:nth-child(2) {
    //float: right;
  }
}

.checkbox_checked {
  background-color: #DEF3FD !important;

  ul li div {
    background-color: #DEF3FD !important;
  }

  button {
    color: black !important;
  }
}

.city_list_style {
  position: absolute;
  border-radius: 4px;
  background-color: white;
  z-index: 100;
  width: 11.67vw;
  max-height: 15.99vw;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1.04vw 0.83vw;
  box-shadow: 0 4px 12px 0 #00000014;

  ul li {
    line-height: 1.5vw;
  }

}

.black_list_block {
  position: absolute;
  width: 12.08vw;
  height: 10.36vw;
  border-radius: 4px;
  background-color: white;
  z-index: 100;
  box-shadow: 0 4px 12px 0 #00000014;
  padding: 1.04vw 0.83vw;
  text-align: left;
  font-size: 0.83vw;
  color: #999999;
  line-height: 1.6vw;
  right: 0;

  ul li:nth-child(1) {
    color: black;
    font-weight: 500;
  }

  button {
    border-radius: 4px;
    border: 1px solid #04B0FB;
    background-color: white;
    color: #999999;
    width: 10.42vw;
    height: 1.82vw;

  }
}


.container_form {
  //max-width: 1920px;
  max-width: 100%;
  background: rgb(246, 250, 254);
  //margin: 0 0 0 5.21vw;
  //padding: 0 50px;
  padding-bottom: 50px;
}

.cleaner_form_block {
  box-shadow: 0 4px 12px 0 #00000014;
  background-color: white;
  border-radius: 4px;
  padding: 40px;
  margin-top: 3vw;


}

.info_options_about_cleaner.form {
  display: flex;
  align-items: center;

  div:nth-child(2) svg {
    width: 1.46vw;
    height: 1.46vw;
    position: relative;
    //top: 1px;
    bottom: -4px;
    right: -16px;
    z-index: 101;
  }

  div:nth-child(2) > div {
    position: relative;
    border: none !important;

    > div > div {
      padding: 0;
      font-size: 1.04vw;
      font-weight: 600;
    }

    div > div:nth-child(2) {
      left: 0;
      top: 1vw;
    }

    div > div:nth-child(2) > ul {
      border: none !important;

    }
  }

  div:nth-child(3),
  div:nth-child(4) {
    display: flex;
    align-items: center;
  }
}

.cleaner_info_name.input {
  position: relative;

  input[type=text] {
    border: none;
    outline: none;
    border-bottom: 1px solid #04B0FB;
    font-size: 0.83vw;
  }

  div:nth-child(2) div:nth-child(1) > svg:nth-child(1) {
    position: absolute;
    z-index: 1;
    left: -3%;
    width: 1.02vw;
  }

  div:nth-child(2) > div:nth-child(2) > svg:nth-child(1) {
    position: absolute;
    z-index: 1;
    left: 11.5vw;
    top: 19%;
    width: 1.02vw;
  }
}

.cleaner_info_name_select.form_left {
  svg {
    position: absolute;
    left: 9vw;
    width: 1.02vw;
  }
}

.cleaner_info_name_select.form_right {
  svg {
    position: absolute;
    right: -2%;
    width: 1.02vw;
  }
}

.data_group_form {
  margin-right: 7vw;
}

.cleaner_form_name {
  width: 27.19vw;
}

.cleaner_form_number,
.cleaner_form_mail {
  width: 10.42vw;
  line-height: 1.5vw;
  padding-left: 1.1vw;
}

.cleaner_form_city {
  border: none;
  outline: none;
  border-bottom: 1px solid #04B0FB;
  width: 12.29vw;
  font-size: 0.83vw;

  &::placeholder {
    color: #999999;
  }
}

.personal_data_form {
  text-align: left;
  margin-top: 70px;

  input[type=text] {
    border: none;
    outline: none;
    border-bottom: 1px solid #04B0FB;
    width: 27.60vw;
    font-size: 0.83vw;
    min-height: 38px;
    padding-top: 17px;

    &::placeholder {
      color: #999999;
    }
  }

  span {
    font-size: 28px;
    font-weight: 500;
  }

  div div:nth-child(2) div:nth-child(4) {
    display: flex;
    align-items: center;
  }

  > div {
    margin-top: 20px;
    display: flex;
    gap: 3.5vw;
    padding-bottom: 2vw;
    border-bottom: 1.5px solid #04B0FB;

    > div:nth-child(2) > div:nth-child(4) {
      color: #999999;
      font-size: 0.83vw;
      padding-top: 17px;
    }
  }
}

.documents_blocks_official_comment.form_comment {

  > div:nth-child(2) {
    width: 79.8%;
    box-shadow: 0 4px 12px 0 #0000000A;
    background-color: #DEF3FD;
    display: flex;

    ul > li:nth-child(1) {
      display: block;
    }
  }
}

.form_comment {
  div:nth-child(2) div {
    background-color: #DEF3FD;
  }
}

.comission_input_second.input_staff_form {
  width: 86%;
  margin-right: 1.7vw;
}

.staff_form_claims {
  ul li button {
    background-color: #CCCCCC;
    color: white;
  }
}


.cleaner_comment {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
}

.cleaner_comment_btn {
  position: absolute;
  bottom: 0;
  right: 0.3vw;
  width: 6.98vw !important;
  height: 1.82vw !important;
  background-color: white !important;
  color: black !important;
  border: none !important;
  outline: none !important;
  border-radius: 4px !important;
}

#cleaner_comment_text::-webkit-scrollbar {
  width: 5px; /* Adjust the width as needed */

}

#cleaner_comment_text::-webkit-scrollbar-thumb {
  background-color: #04B0FB; /* Change the thumb color */

}

#cleaner_comment_text::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Change the track color */

}

#cleaner_comment_text::-webkit-scrollbar-thumb:hover {
  background-color: #04B0FB; /* Change the thumb color on hover */

}

#cleaner_comment_text {
  scrollbar-width: thin; /* or auto for default width */
  scrollbar-color: #04B0FB #f1f1f1; /* thumb and track colors */

}

#cleaner_comment_text:hover {
  scrollbar-color: #04B0FB #f1f1f1; /* thumb color on hover */
}

#cleaner_comment_text {
  border: none;
  width: 100%;
  height: 100%;
  resize: none;
  background-color: #DEF3FD;
  color: black;
  outline: none;
  font-size: 0.83vw;
  padding-top: 0.17vw;
  padding-left: 0.29vw;
  padding-right: 10vw;
}

#cleaner_comment_text::placeholder {
  color: #04B0FB;
}


#cleaner_card_text::-webkit-scrollbar {
  width: 5px; /* Adjust the width as needed */

}

#cleaner_card_text::-webkit-scrollbar-thumb {
  background-color: #04B0FB; /* Change the thumb color */

}

#cleaner_card_text::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Change the track color */

}

#cleaner_card_text::-webkit-scrollbar-thumb:hover {
  background-color: #04B0FB; /* Change the thumb color on hover */

}

#cleaner_card_text {
  scrollbar-width: thin; /* or auto for default width */
  scrollbar-color: #04B0FB #f1f1f1; /* thumb and track colors */

}

#cleaner_card_text:hover {
  scrollbar-color: #04B0FB #f1f1f1; /* thumb color on hover */
}


#cleaner_card_text {
  border: none;
  width: 100%;
  height: 100%;
  resize: none;
  background-color: #DEF3FD;
  color: #04B0FB;
  outline: none;
  font-size: 0.83vw;
  padding-top: 0.17vw;
  padding-left: 0.29vw;
  padding-right: 10vw;
  scrollbar-width: thin;
  scrollbar-color: #04B0FB #f1f1f1;
}

#cleaner_card_text::placeholder {
  color: #04B0FB;
}

.staff_form_btn {
  margin-top: 40px;
  text-align: left;

  > button:nth-child(1) {
    color: white;
    background-color: #04B0FB;
    width: 14.43vw;
    height: 2.86vw;
    border-radius: 4px;
    font-size: 0.83vw;
    margin-right: 30px;
  }

  > button:nth-child(2) {
    background-color: white;
    color: #F93F3F;
    border: 2px solid #F93F3F;
    width: 14.43vw;
    height: 2.86vw;
    border-radius: 4px;
    font-size: 0.83vw;
  }
}

.work_schedule {
  > div {
    display: flex;
    justify-content: space-between;
    font-size: 1.46vw;
    font-weight: 500;
    position: relative;

    .work_schedule_hidden {
      display: none;
      position: absolute;
      color: #999999;
      font-weight: 400;
      font-size: 0.83vw;
      bottom: -0.5vw;
      left: 10.9vw;
      width: 34.69vw;
      height: 2.66vw;
      box-shadow: 0 4px 12px 0 #00000029;
      background-color: white;
      padding: 0.83vw 1.25vw;

    }

    svg:hover + .work_schedule_hidden {
      display: block;
    }
  }
}
