.work-schedule-section {

  .work-schedule-content {
    display: flex;
    column-gap: 40px;
  }

  .work-schedule-title {
    margin-bottom: 32px;
  }

  .work-time-card-wrapper {
    padding-top: 28px;
  }
}
